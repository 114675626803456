import React from "react";
import s from "./PopupContent.module.scss";
import StepContainer from "./StepContainer/StepContainer";

export default function PopupContent() {
  const roadMap = [
    { id: 1, title: "Pre-Launch", progress: "done" },
    { id: 2, title: "Referrals", progress: "done" },
    { id: 3, title: "Earn Tasks", progress: "done" },
    { id: 4, title: "Daily Rewards", progress: "done" },
    { id: 5, title: "Basic Game", progress: "done" },
    { id: 6, title: "Splitting Pool Technology", progress: "done" },
  ];
  return (
    <div className={s.content}>
      <h2 className={s.title}>Road Map</h2>
      {roadMap.map((r) => (
        <React.Fragment key={r.id}>
          <StepContainer title={r.title} progress={r.progress} />
        </React.Fragment>
      ))}

      <h2 style={{ marginTop: "15px" }} className={s.comingSoon}>
        Coming Soon
      </h2>
      <div className={s.step}>Auto Mining Rewards</div>
      <div className={s.step}>Rank Ratings</div>
      <div className={s.step}>AirDrop - Dec 25</div>
      <div className={s.step}>PVP Battles</div>
      <div className={s.step}>Web Game</div>

      <div className={s.listing}>Listing on Exchanges - Dec, 2024</div>
    </div>
  );
}
