import Button from "components/UI/Buttons/Button/Button";
import s from "./FollowSocialNetwork.module.scss";
import { ActionType } from "api/leagues";
import { usersApi } from "../../../api/users";
import { MouseEvent, useEffect, useState } from "react";

export default function FollowSocialNetwork({
  action,
  isCompleted,
  userId,
  refLink,
}: {
  action: ActionType;
  isCompleted: boolean;
  userId: number;
  refLink: string;
}) {
  const [linkX, setLinkX] = useState("");
  console.log(action);

  function getFirstWord(str: string) {
    if (!str) return ""; // Проверяем, что строка не пустая
    const words = str.split(" "); // Разделяем строку по пробелам
    return words[0]; // Возвращаем первое слово
  }

  async function handleClickAction(e: MouseEvent) {
    try {
      await usersApi.performAction(action.id, userId, action.league_id);
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    let link = action.link;

    link = link.replace(/__referal_link__/g, encodeURIComponent(refLink));

    setLinkX(link);
  }, []);

  return (
    <div className={`${s.container} ${isCompleted ? s.completed : ""}`}>
      <div className={s.info}>
        <h2 className={s.exercise}>{action?.name}</h2>
        <div className={s.image}>
          <img src="/leagues/test.png" alt="points" />
        </div>
        <div className={s.points}>{action?.reward}</div>
      </div>
      {action.code === "share_x" ? (
        <Button
          isCompleted={isCompleted}
          to={linkX || ""}
          onClick={handleClickAction}
        >
          {/* {<img src="/leagues/test.png" alt="" />} */}
          {isCompleted ? "Completed" : getFirstWord(action?.name)}
        </Button>
      ) : (
        <Button
          isCompleted={isCompleted}
          to={action.link || ""}
          onClick={handleClickAction}
        >
          {/* {<img src="/leagues/test.png" alt="" />} */}
          {isCompleted ? "Completed" : getFirstWord(action?.name)}
        </Button>
      )}
      <div className={s.description}>{action?.name} to earn points</div>
    </div>
  );
}
