import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";
import s from "./Button.module.scss";

export default function Button({
  to,
  children,
  isCompleted,
  onClick,
  style,
}: {
  to: string;
  children: ReactNode;
  isCompleted: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  style?: CSSProperties;
}) {
  return (
    <Link
      style={style}
      className={`${s.btn} ${isCompleted ? s.disable : ""}`}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
