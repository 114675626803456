import { ActionsLinks, ActionType, LeagueType } from "api/leagues";
import { ScoresType } from "api/users";
import LeaguesPage from "components/LeaguesPage/LeaguesPage";

export default function Leagues({
  leagues,
  scores,
  setActiveLeague,
  actions,
  actionsLinks,
}: {
  leagues: LeagueType[] | null;
  scores: ScoresType[];
  setActiveLeague: (league: LeagueType) => void;
  actions: ActionType[];
  actionsLinks: ActionsLinks | null;
}) {
  return (
      <LeaguesPage
          setActiveLeague={setActiveLeague}
          scores={scores}
          leagues={leagues}
          actionsLinks={actionsLinks}
          // actions={actions}
      />
  );
}
