import { Link } from "react-router-dom";
import s from "./League.module.scss";
import { LeagueType, Season } from "api/leagues";
import { getElementsSum } from "utils/getElementsSum";
import { ScoresType } from "api/users";

export default function League({
  league,
  href,
  image,
  season,
  scores,
  setActiveLeague,
}: {
  league: string;
  href: LeagueType;
  key?: number;
  image: string | null;
  season: Season;
  leagueId: number;
  scores: ScoresType | undefined;
  setActiveLeague: (league: LeagueType) => void;
}) {
  return (
    <div className={s.container}>
      {!season?.id ? (
        ""
      ) : (
        <Link
          to={"/tapper"}
          className={s.link}
          onClick={() => setActiveLeague(href)}
        />
      )}

      <img className={s.background} src={image || ""} alt={league} />
      <h3 className={s.title}>{league} league</h3>
      {/*<div className={s.blockchain}>Stacks</div>*/}
      <div className={s.profileRankContainer}>
        <div className={s.scoresContainer}>
          <div className={s.currentScoresTitle}>Current scores</div>
          <div className={s.currentScoresPoints}>
            <img src="/leagues/test.png" alt="money" />{" "}
            <span className={s.points}>{scores?.score || 0}</span>
          </div>
        </div>
        <div className={s.ratingContainer}>
          <div className={s.yourPlaceTitle}>Your Place</div>
          <div className={s.place}>{scores?.position || "-"}</div>
        </div>
      </div>
      <div className={s.aboutLeagueContainer}>
        <div className={s.aboutLeague}>
          <div>
            <span className={s.leagueTitle}>{league} league</span> -{" "}
            <span className={s.seasonNumber}> {season?.name}</span>
          </div>
          <div className={s.endsIn}>Ends in: {season?.end_time}</div>
        </div>
        <div className={s.prizePoolContainer}>
          <div className={s.prizePoolTitle}>Prize Pool</div>
          <div className={s.prizePooValue}>
            ${getElementsSum(season?.prizes || [])}
          </div>
        </div>
      </div>
    </div>
  );
}
