import React from "react";
import Reward from "./Reward/Reward";
import s from "./RewardsPage.module.scss";
import { LeagueType } from "api/leagues";
export default function RewardsPage({ league }: { league: LeagueType | null }) {
  const prizes = league?.seasons[league?.seasons?.length - 1]?.prizes;

  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.container}>
          <div className={s.image}>
            <img src={league?.list_cover || ""} alt={league?.name} />
          </div>

          <h2 className={s.title}>{league?.name} league</h2>
        </div>

        {prizes
          ? prizes.map((r, index) => (
              <React.Fragment key={index}>
                <Reward place={index + 1} prize={r} />
              </React.Fragment>
            ))
          : "Prizes not found"}
      </div>
    </section>
  );
}
