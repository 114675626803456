import { useState } from "react";
import s from "./Popup.module.scss";
import PopupContent from "./PopupContent/PopupContent";

export default function Popup({ closePopup }: { closePopup: () => void }) {
  const [isShow, setIsShow] = useState(false);

  const toggleCheck = () => {
    setIsShow((prev) => !prev);
  };

  const onClosePopup = () => {
    closePopup();
    saveToLocalStorage();
  };
  //close popup
  const saveToLocalStorage = () => {
    localStorage.setItem("isOpen", JSON.stringify(!isShow));
  };
  return (
    <div className={s.container}>
      <div className={s.close} onClick={onClosePopup}>
        x
      </div>
      <PopupContent />

      <div
        onClick={toggleCheck}
        className={`${s.checkContainer} ${isShow ? s.active : ""}`}
      >
        <div className={`${s.checkbox}  `}>
          <div className={`${s.check}`}></div>
        </div>
        Do not display
      </div>

      {/* <img src="/popup/bg-2.png" alt="" /> */}
    </div>
  );
}
