import Button from "components/UI/Buttons/Button/Button";
import s from "./InviteFriend.module.scss";
import BlackButton from "components/UI/Buttons/BlackButton/BlackButton";

export default function InviteFriend({
  refLink,
  isCompleted,
}: {
  refLink: string;
  isCompleted: boolean;
}) {
  const refLinkText = `Click with me on QuickClick mini game! Over $20,000 USDT in prize money given away to highest clickers every month 🏆💵 Use the boost to earn more points 🚀 First 10,000 users get BONUS TOKEN AIRDROP!! 🎁`;

  const copyLink = () => {
    navigator.clipboard
      .writeText(refLink)
      .then(() => {
        alert("Link copied: " + refLink);
      })
      .catch((err) => {
        console.error("Copy error: ", err);
      });
  };
  return (
    <div
      className={`${s.inviteFriend} ${s.booster} ${
        isCompleted ? s.completed : ""
      }`}
    >
      <div className={s.inviteFriendInfo}>
        <h2 className={s.inviteFriendTitle}>Invite friend Now</h2>
        <div className={s.inviteFriendPrice}>
          <div className={s.image}>
            {" "}
            <img src="/leagues/test.png" alt="points" />
          </div>
          50,000
        </div>
      </div>
      <Button
        isCompleted={false}
        to={`https://t.me/share/url?url=${refLink}&text=${encodeURIComponent(
          refLinkText
        )}`}
      >
        {isCompleted ? "Completed" : "Share"}
      </Button>
      <div className={s.inviteFriendDescription}>
        {" "}
        50,000 points for inviting a friend
      </div>
      <div className={s.inviteFriendOr}>Or</div>
      <div className={s.inviteFriendLinkContainer}>
        <div className={s.inviteFriendLinkTitle}>Copy link</div>
        <input className={s.input} readOnly type="text" value={refLink} />
        <BlackButton onClick={copyLink}>Copy link</BlackButton>
      </div>
    </div>
  );
}
