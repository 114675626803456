import Button from "components/UI/Buttons/Button/Button";
import s from "./Challenge.module.scss";
import { ActionType } from "api/leagues";

export default function Challenge({
  action,
  isCompleted,
}: {
  action: ActionType;
  isCompleted: boolean;
}) {
  return (
    <div className={`${s.container} ${isCompleted ? s.completed : ""}`}>
      <h2 className={s.title}>PARTNERSHIP CHALLENGE</h2>
      <div className={s.offer}>EARN UP TO $500</div>
      <p className={s.text}>
        Join our mission to expand our League system! 👍 Bring in projects or
        Telegram groups to join QuickClick and receive $500 commission. The more
        partners you recruit, the greater benefits you’ll receive.
      </p>
      <Button isCompleted={isCompleted} to={action?.link || ""}>
        {" "}
        {isCompleted ? "Completed" : "Contact us"}
      </Button>
    </div>
  );
}
