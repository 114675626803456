import { ActionType, LeagueType } from "api/leagues";

import BoostPage from "components/BoostPage/BoostPage";

export default function Boost({
  league,
  completedActions,
  actions,
  refLink,
}: {
  league: LeagueType | null;
  completedActions: ActionType[];
  actions: ActionType[];
  refLink: string;
}) {
  return (
    <>
      <BoostPage
        league={league}
        completedActions={completedActions}
        actions={actions}
        refLink={refLink}
      />
    </>
  );
}
