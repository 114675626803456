import { ReactNode } from "react";
import { Link } from "react-router-dom";
import s from "./BlackButton.module.scss";

export default function BlackButton({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) {
  return (
    <button className={s.btn} onClick={onClick}>
      {children}
    </button>
  );
}
