// import WalletBalance from "components/WalletConnect/WalletBalance/WalletBalance";
import { GetUserData } from "api/users";
import WalletConnect from "components/WalletConnect/WalletConnect";
import { memo } from "react";

function Wallet({
  userData,
  commonScores,
  refsCount,
}: {
  userData: GetUserData | null;
  commonScores: number;
  refsCount: number;
}) {
  return (
    <>
      <WalletConnect
        commonScores={commonScores}
        refsCount={refsCount}
        userData={userData}
      />
    </>
  );
}

export default memo(Wallet);
