import { NavLink, useLocation } from "react-router-dom";
import s from "./Link.module.scss";
import { ReactNode } from "react";

export default function Link({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  const { pathname } = useLocation();

  // const onVibration = () => {
  //   if (window.navigator.vibrate) {
  //     window.navigator.vibrate([1000, 50, 1000]);
  //   } else {
  //     alert(JSON.stringify(window.navigator));
  //   }
  // };
  // alert(JSON.stringify(href));
  // alert(JSON.stringify(pathname));
  return (
    <NavLink
      // className={({ isActive }) => {
      //   return [isActive ? s.active : "", s.link].join(" ");
      // }}
      className={`${s.link} ${href === pathname ? s.active : ""}`}
      to={href}
    >
      {children}
    </NavLink>
  );
}
