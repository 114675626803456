import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import { useCallback, useEffect, useState } from "react";
import Leagues from "pages/Leagues/Leagues";
import Rewards from "pages/Rewards/Rewards";
import Wallet from "pages/Wallet/Wallet";
import Loading from "components/common/Loading/Loading";
import { ActionsLinks, ActionType, leaguesApi, LeagueType } from "api/leagues";
import { GetUserData, ScoresType, usersApi } from "api/users";
import Popup from "components/common/Popup/Popup";
import Tapper from "pages/Tapper/Tapper";
import Boost from "pages/Boost/Boost";

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id;

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [leagues, setLeagues] = useState<LeagueType[] | null>(null); //type
  const [activeLeague, setActiveLeague] = useState<LeagueType | null>(null); //type
  const [scores, setScores] = useState<ScoresType[]>([]);
  const [actions, setActions] = useState<ActionType[]>([]);
  const [completedActions, setCompletedActions] = useState<ActionType[]>([]);
  const [isShowPopup, setIsShowPopup] = useState(true);
  const [refLink, setRefLink] = useState("");
  const [userData, setUserData] = useState<GetUserData | null>(null);
  const [commonScores, setCommonScores] = useState(0);
  const [refsCount, setRefsCount] = useState(0);
  const [actionsLinks, setActionsLinks] = useState<ActionsLinks | null>(null);

  const history = useNavigate();

  //back button on tg
  const backButton = tg.BackButton;
  backButton.show();
  tg.onEvent("backButtonClicked", function () {
    history("/tapper");
  });

  tg.expand(); // 100% height

  const closePopup = useCallback(() => {
    setIsShowPopup(false);
  }, []);

  useEffect(() => {
    async function getActions() {
      console.log('active', activeLeague)
      if (!activeLeague) return;

      setIsLoading(true);

      try {
        const [
          actionsRes,
          actionsLinksRes,
          completedActionsRes,
          scores,
          commonScores,
          refsCount,
        ] = await Promise.all([
          leaguesApi.getActions(activeLeague.id),
          leaguesApi.getActionsLinks(userId),
          usersApi.getCompletedActions(activeLeague.id, userId),
          usersApi.getScores(userId),
          usersApi.getCommonScores(userId),
          usersApi.getRefs(userId),
        ]);

        if (activeLeague) {
          const refLink = await usersApi.getRefLink(userId, activeLeague?.id);
          setRefLink(refLink);
        }
        if (commonScores) {
          setCommonScores(commonScores);
        }
        if (refsCount.length) {
          setRefsCount(refsCount.length - 1);
        }

        setScores(scores);
        setCompletedActions(completedActionsRes);
        setActions(actionsRes);

        setActionsLinks(actionsLinksRes)
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    getActions();
  }, [activeLeague]);

  useEffect(() => {
    const savedValue = localStorage.getItem("isOpen");
    if (savedValue) {
      setIsShowPopup(JSON.parse(savedValue));
    }

    async function getData() {
      setIsLoading(true);

      try {
        const leagues = await leaguesApi.getLeagues();

        if (leagues?.length) {
          setLeagues(leagues);
        }
        const actLeague =
          leagues?.find((league: LeagueType) => league.id === 1) || null;

        setActiveLeague(actLeague);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getCommonScores() {
      try {
        const userData = await usersApi.getUserData(userId);

        if (userData) {
          setUserData(userData);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getCommonScores();
  }, [userId]);

  if (!navigator?.userAgent?.toLowerCase()?.includes("mobi")) {
    return (
      <div className="not-mobile">
        Please, open the application on your mobile device.
      </div>
    );
  }

  const renderLoading = () => (isLoading ? <Loading /> : null);

  const renderPopup = () =>
    isShowPopup ? <Popup closePopup={closePopup} /> : null;
  return (
    <div className="App">
      {renderLoading()}
      {renderPopup()}
      <Routes>
        <Route path="/" element={<Navigate to="/tapper" replace />} />
        <Route
          path="/boost"
          element={
            <Boost
              actions={actions}
              completedActions={completedActions}
              league={activeLeague}
              refLink={refLink || ""}
            />
          }
        />
        <Route
          path="/leagues"
          element={
            <Leagues
              setActiveLeague={setActiveLeague}
              scores={scores}
              leagues={leagues}
              actions={actions}
              actionsLinks={actionsLinks}
            />
          }
        />
        <Route path="/rewards" element={<Rewards league={activeLeague} />} />
        <Route
          path="/wallet"
          element={
            <Wallet
              commonScores={commonScores}
              refsCount={refsCount}
              userData={userData}
            />
          }
        />
        <Route path="/tapper" element={<Tapper league={activeLeague} />} />
      </Routes>
      <Navigation />
    </div>
  );
}

export default App;
