import { LeagueType } from "api/leagues";
import TapperPage from "components/TapperPage/TapperPage";

export default function Tapper({ league }: { league: LeagueType | null }) {
  return (
    <>
      <TapperPage league={league} />
    </>
  );
}
