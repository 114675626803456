import s from "./StepContainer.module.scss";

export default function StepContainer({
  title,
  progress,
}: {
  title: string;
  progress: string;
}) {
  return (
    <div className={s.stepContainer}>
      <div className={s.step}>{title}</div>
      <div className={s.progress}>
        {progress === "done" ? `✅ ${progress}` : progress}
      </div>
    </div>
  );
}
