import CountdownTimer from "components/CountDownTimer/CountDownTimer";
import s from "./TapperPage.module.scss";
import { TouchEvent, useCallback, useEffect, useState } from "react";
import Energy from "./Energy/Energy";
import { usersApi } from "api/users";
import { leaguesApi, LeagueType } from "api/leagues";

export default function TapperPage({ league }: { league: LeagueType | null }) {
  const [clicks, setClicks] = useState<{ id: number; x: number; y: number }[]>(
    []
  );
  const leagueId = league?.id || 1;
  const seasonId = league?.seasons[league?.seasons.length - 1]?.id || 1;
  const [localScores, setLocalScores] = useState(0);
  const [localEnergy, setLocalEnergy] = useState(0);
  const [position, setPosition] = useState<undefined | number>();
  const [isNewSeason, setIsNewSeason] = useState(false);

  const timerData = league?.seasons[league?.seasons?.length - 1].end_time;

  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;

  tg?.BackButton?.hide();

  useEffect(() => {
    async function isFirstPlayOnLeague() {
      try {
        const aboutLeague = await usersApi.isFirstPlayOnLeague(
          userId,
          leagueId
        );
        if (!aboutLeague?.id) {
          setIsNewSeason(true);
          await leaguesApi.joinTheLeague(userId, leagueId || 1, seasonId);
        }
        setLocalScores(Number(aboutLeague.score));

        const [energy, position] = await Promise.all([
          usersApi.getEnergy(userId, leagueId),
          usersApi.getScores(userId),
        ]);

        const league = position.find((p) => p.league_id === leagueId);
        setPosition(league?.position);
        setLocalEnergy(+energy);
      } catch (e) {
        console.log(e);
      }
    }
    isFirstPlayOnLeague();
  }, [isNewSeason, leagueId, userId, seasonId]);

  const handleCardClick = useCallback(
    async (e: TouchEvent<HTMLDivElement>) => {
      tg.HapticFeedback.impactOccurred("soft");
      const touch = e.changedTouches[0];
      const card = e.currentTarget;
      const rect = card.getBoundingClientRect();
      const x = touch.clientX - rect.left - rect.width / 2;
      const y = touch.clientY - rect.top - rect.height / 2;

      card.style.transform = `perspective(1000px) rotateX(${
        -y / 10
      }deg) rotateY(${x / 10}deg) translateX(4px)`;

      setTimeout(() => {
        card.style.transform = "";
      }, 100);

      setClicks([
        ...clicks,
        { id: Date.now(), x: touch.pageX, y: touch.pageY },
      ]);

      if (localEnergy > 0) {
        setLocalScores((prev) => prev + 1);
        setLocalEnergy((prev) => prev - 1);

        if ((localScores + 1) % 2 === 0 || localEnergy === 1) {
          try {
            await usersApi.updateEnergy(userId, leagueId, seasonId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    [
      localEnergy,
      localScores,
      leagueId,
      clicks,
      userId,
      tg.HapticFeedback,
      seasonId,
    ]
  );

  const handleAnimationEnd = (id: number) => {
    setClicks((prevClicks) => prevClicks.filter((click) => click.id !== id));
  };

  const colors = [
    { id: 1, color1: "rgb(173,80,147)", color2: "rgb(64,82,182)" },
    { id: 2, color1: "rgb(106,117,40)", color2: "rgb(198,60,77)" },
    { id: 3, color1: "rgb(49,26,105)", color2: "rgb(99,194,201)" },
    { id: 4, color1: "rgb(126,125,83)", color2: "rgb(64,82,182)" },
    { id: 5, color1: "rgb(173,80,147)", color2: "rgb(0,0,0)" },
  ];

  const color = colors.find((c) => c.id === leagueId);
  const firstColor = color ? color.color1 : "rgb(0,0,0)";
  const twoColor = color ? color.color2 : "rgb(0,0,0)";

  return (
    <section
      style={{
        background: `linear-gradient(to bottom, ${firstColor}, ${twoColor})`,
      }}
      className={s.section}
    >
      <div className="container">
        <div className={s.container}>
          <div className={s.flexGrowContainer}>
            <CountdownTimer
              targetDate={timerData ? timerData : null}
              className={s.timer}
            />
            <div className={s.tapsCount}>Scores: {localScores}</div>
            <div className={s.tapsCount}>Position: {position}</div>
          </div>
          <div
            // onClick={(e) => handleCardClick(e)}
            onTouchStart={(e) => handleCardClick(e)}
            className={`${s.buttonContainer} ${!localEnergy ? s.disable : ""}`}
          >
            <img src={league?.tap_image || ""} alt="" />
          </div>
          <Energy
            energy={localEnergy}
            progress={((localEnergy / 2000) * 100).toString()}
          />
          <div
            className={`${s.disabler} ${!localEnergy ? s.disable : ""}`}
          ></div>
        </div>
      </div>
      {clicks.map((click) => (
        <div
          key={click.id}
          className={s.animation}
          style={{
            top: `${click.y - 42}px`,
            left: `${click.x - 28}px`,
          }}
          onAnimationEnd={() => handleAnimationEnd(click.id)}
        >
          +1
        </div>
      ))}
    </section>
  );
}
