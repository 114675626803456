import {MouseEvent, useEffect, useMemo} from "react";
import League from "./League/League";
import s from "./Leagues.module.scss";
import {ActionsLinks, LeagueType} from "api/leagues";
import { ScoresType, usersApi } from "api/users";
import Button from "components/UI/Buttons/Button/Button";

export default function LeaguesPage({
  leagues,
  scores,
  setActiveLeague,
  actionsLinks,
}: {
  leagues: LeagueType[] | null;
  scores: ScoresType[];
  setActiveLeague: (league: LeagueType) => void;
  actionsLinks: ActionsLinks | null;
}) {
  const tg = window.Telegram.WebApp;
  const userId = tg?.initDataUnsafe?.user?.id;

  console.log('links', actionsLinks)

  async function handleClickAction(actionId: number, leagueId: number) {
    try {
      await usersApi.performAction(actionId, userId, leagueId);
    } catch (e) {
      //
    }
  }

  const leagueItems = useMemo(() => {
    return leagues?.map((l) => {
      const score = scores.find((sc) => sc.league_id === l.id);
      return (
        <>
          <Button
              style={{ marginBottom: "10px" }}
              isCompleted={false}
              to={actionsLinks?.[l.id]?.link ?? ''}
              onClick={() => handleClickAction(actionsLinks?.[l.id]?.id as number, l.id)}
          >
            {`Join ${l.name.replace(' league', '')} chat ${actionsLinks?.[l.id]?.reward} points`}
          </Button>
          <div key={l.id} className={s.leaguesContainer}>
            <League
              leagueId={l.id}
              league={l.name}
              href={l}
              image={l.list_cover}
              season={l?.seasons[l?.seasons.length - 1]}
              scores={score}
              setActiveLeague={setActiveLeague}
            />
          </div>
        </>
      );
    });
  }, [leagues, scores]);

  return (
    <section className={s.section}>
      <div className="container">
        <h2 className={s.title}>TOP TRENDING LEAGUES</h2>
        <h3 className={s.subtitle}>
          {" "}
          Earn more rewards through each leagues boost tasks
        </h3>
        {leagueItems}
      </div>
    </section>
  );
}
