import s from "./WalletConnect.module.scss";

import {
  TonConnectButton,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
import { GetUserData } from "api/users";
import React, { useEffect } from "react";
import { formatAddress } from "utils/formatAddress";
import Description from "./Descriotion/Description";
import { Link, useNavigate } from "react-router-dom";
const description = [
  {
    title: "WHATS QUICK CLICK?",
    text: "It’s a play to earn game. Tap the screen to earn Quick points. User with the highest points wins cash prize end of every month!",
  },
  {
    title: "HOW TO EARN MORE?",
    text: "Quick Leagues! Join a different league to win their additional cash prize pools. Each league has its own boost tasks. Make sure to check every leagues boost.",
  },
  {
    title: "WHAT IS A LEAGUE?",
    text: "If you want to promote your project, business/community. You can contact us. We will create you a league were users can complete tasks to earn rewards. You decide how high the rewards and prizes are for completed tasks!",
  },
  {
    title: "EARN MORE!? $500 AFFILIATE COMMISSION!",
    text: "For every new project referred to Quick Click that starts a league you will earn $500 Remember admins will never message you first. Be careful of others posing as Quick Click Admins.",
  },
];
const WalletConnect = ({
  userData,
  commonScores,
  refsCount,
}: {
  userData: GetUserData | null;
  commonScores: number;
  refsCount: number;
}) => {
  const address = useTonAddress(false); // Получаем сырой адрес
  const wallet = useTonWallet();

  return (
    <section className={s.container}>
      <div className="container">
        <div className={s.flex}>
          {" "}
          {address && (
            <>
              <h2 className={s.address}>
                Connected wallet : {formatAddress(address)}
              </h2>
            </>
          )}
          <TonConnectButton />
        </div>

        {wallet?.account.address && (
          <div className={s.userData}>
            <div className={s.userPhoto}>
              <img src={userData?.avatar || ""} alt="" />
            </div>
            <div className={s.userName}>{userData?.name}</div>
          </div>
        )}
        <div className={s.statisticsContainer}>
          <h3 className={s.statistics}>Statistics</h3>
          <div className={s.scoresCount}>
            Total tapped: <span>{commonScores || 0}</span>
          </div>
          <div className={s.scoresCount}>
            Friends referred:
            <span>{refsCount || 0}</span>
          </div>
        </div>
        <div className={s.description}>
          {description.map((d, index) => (
            <React.Fragment key={index + d.title}>
              <Description text={d.text} title={d.title} />
            </React.Fragment>
          ))}
        </div>
        <div className={s.bottomText}>
          Link to support/affiliate
          <Link to={"https://t.me/QuickClickSupport"}>
            https://t.me/QuickClickSupport
          </Link>
        </div>
      </div>
    </section>
  );
};

export default WalletConnect;
