import s from "./BoostPage.module.scss";
import InviteFriend from "./InviteFriend/InviteFriend";
import FollowSocialNetwork from "./FollowSocialNetwork/FollowSocialNetwork";
import React from "react";
import { ActionType, LeagueType } from "api/leagues";
import Challenge from "./Challenge/Challenge";

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id as number;

export default function BoostPage({
  league,
  completedActions,
  actions,
  refLink,
}: {
  league: LeagueType | null;
  completedActions: ActionType[] | [];
  actions: ActionType[] | undefined;
  refLink: string;
}) {
  return (
    <section className={s.container}>
      <div className="container">
        <div className={s.info}>
          {" "}
          <div className={s.logo}>
            {/* <img src="../../assets/images/leagues/QuickClick.png" alt="logo" /> */}
            <img src={league?.list_cover || ""} alt="logo" />
          </div>
          <h2 className={s.title}>
            Get boost & <span>earn points</span>
          </h2>
          {actions?.map((a) => {
            const isCompleted = completedActions.find(
              (c) => c.action_id === a.id
            );
            if (a.code === "challenge") {
              return (
                <React.Fragment key={`${a.id} ${a.code}`}>
                  {" "}
                  <Challenge isCompleted={!!isCompleted} action={a} />
                </React.Fragment>
              );
            } else if (a.code === "invite") {
              return (
                <React.Fragment key={`${a.id} ${a.code}`}>
                  {" "}
                  <InviteFriend isCompleted={!!isCompleted} refLink={refLink} />
                </React.Fragment>
              );
            } else {
              //   a.code === "join" || a.code === "join" ||  a.code === "subscribe"
              return (
                <React.Fragment key={`${a.id} ${a.code}`}>
                  {" "}
                  <FollowSocialNetwork
                    action={a}
                    isCompleted={!!isCompleted}
                    userId={userId}
                    refLink={refLink}
                  />
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}
