import React, { useState, useEffect, memo } from "react";
import s from "./CountDownTimer.module.scss";

type CountdownTimerProps = {
  className?: string;
  targetDate: string | null;
};

const CountdownTimer: React.FC<CountdownTimerProps> = memo(
  ({ className, targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<number>(0);

    useEffect(() => {
      if (!targetDate) return;
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const target = new Date(targetDate).getTime(); // Преобразуем строку в объект Date
        const distance = target - now;
        setTimeLeft(distance);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [targetDate]);

    // Вычисляем дни, часы, минуты и секунды
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    // Проверка на завершение отсчета
    if (timeLeft < 0) {
      return <div className={s.timeLeft}></div>;
    }

    return (
      <div className={`${className ? className : ""} ${s.container}`}>
        <p className={s.title}>Time left till the end of the season :</p>
        <div className={s.timer}>
          {timeLeft < 0
            ? "Время истекло!"
            : `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`}
        </div>
      </div>
    );
  }
);

export default CountdownTimer;
